<template>
    <router-link :to="{ name: routeName }" >
      <div class="item">
        <i class="material-icons">{{matIcon}}</i>
        <div class="text">{{text}}</div>
      </div>
    </router-link>
</template>

<script>
export default {
  name: 'AdminItem',
  props: {
    routeName: {
      type: String,
      required: true
    },
    matIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .item {
    width: 200px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    margin: 5px;
    box-shadow: 0 2px 3px #bbb;
    padding-top: 20px;
    border-radius: 5px;
    transition: background-color 0.5s ease;
  }
  .item:hover {
    background-color: rgba(0,0,0,0.1);
  }
  a {
    text-decoration: none;
  }
  i {
    color: darkslategray;
    font-size: 3em;
  }
  .text {
    color: black;
  }
</style>