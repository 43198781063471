<template>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <PageContent>
    <SubHeading>Administration</SubHeading>
    
    <div class="Items">
      <AdminItem routeName="Articles" matIcon="article" text="Article Management" />
      <AdminItem routeName="EditCards" matIcon="calendar_today" text="Card Management" />
      <AdminItem routeName="Images" matIcon="image" text="Image Management" />
      <AdminItem routeName="Files" matIcon="insert_drive_file" text="File Management" />
      <AdminItem routeName="Menu" matIcon="menu" text="Menu Management" />
      
      <div @click="Logout" class="logout">
        <i class="material-icons icon">exit_to_app</i>
        <div>Logout</div> 
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/components/PageContent.vue'
import SubHeading from '@/components/SubHeading.vue'
import AdminItem from '@/components/Admin/AdminItem.vue'

import AuthService from '@/services/AuthService'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted } from 'vue'
export default {
  
  name: "Admin",
  components: {
    PageContent,
    SubHeading,
    AdminItem
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    function Logout(){
      AuthService.logout();
      store.commit('logout');
      router.push('/');
    }
    onMounted(() => {
      if (!AuthService.getAuthFromSession()){
        router.push('/');
      }
    });
    return {Logout}
  }
}
</script>

<style scoped>
  .Items{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .logout {
    width: 200px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    margin: 5px;
    box-shadow: 0 2px 3px #bbb;
    padding-top: 20px;
    border-radius: 5px;
    transition: background-color 0.5s ease;
    color: black;
    cursor: pointer;
  }
  .icon {
    font-size: 3em;
    color: red;
  }
  .logout:hover {
    background-color: rgba(255,0,0,0.1);
  }
</style>